/**
 * This stores the Admin App's state. It is kept separate from the Main Root Reducer to help reduce the chances of a conflict.
 */

import { CATEGORY_TYPES } from '../constants/action-types';

const initialState = {
  dataset: [],
};

export default function categoryReducer(state = initialState, action) {
  const newState = {};
  if (CATEGORY_TYPES[action.type]) {
    newState[CATEGORY_TYPES[action.type]] = action.payload;
    return Object.assign({}, state, newState);
  }

  return state;
}
