/**
 * This file contains most of the actions used around the application
 */

import { apiClient } from '../../common/axios_instance';
import { getUsersSavedIncidents } from './incidents';
import { PROCESSENV } from '../../common/processenv';

const CALLDOWN_INCIDENT_SETTING = 'calldownSelectedIncident';
// const CALLDOWN_ACTIVE_SETTING = 'calldownActiveSetting';

/**
 * This sets the default info right after the user logs in
 * @param {Object} payload
 */
export function setDefaultInfo(payload) {
  return async function (dispatch, _getState) {
    // Set Account Info

    dispatch({ type: 'SET_USER_ACCOUNT_INFO', payload: payload.accountInfo });
    dispatch({ type: 'SET_SELECTED_GROUP', payload: payload.selectedGroupID });

    // Set Active Group Name
    let activeGroupName = '';
    for (let i = 0; i < payload.currentGroups.length; i++) {
      if (payload.currentGroups[i].i === payload.selectedGroupID) {
        activeGroupName = payload.currentGroups[i].n;
        break;
      }
    }

    dispatch({ type: 'SET_SELECTED_GROUP_NAME', payload: activeGroupName });
    dispatch({ type: 'SET_APP_PERMISSIONS', payload: payload.appPermissions });

    // Get users selected incidents
    let fIns = {
      where: {
        and: [
          {
            pvAccountID: payload.accountInfo.id,
          },
          {
            pvSettingType: 'selectedIncidents',
          },
        ],
      },
    };
    let incidentSettingsRes = await apiClient.get(
      PROCESSENV.REACT_APP_PALMETTO_ENDPOINT + '/api/settings?filter=' + JSON.stringify(fIns),
    );

    const userSettings = [];
    if (incidentSettingsRes && incidentSettingsRes.data && incidentSettingsRes.data.length) {
      userSettings.push(incidentSettingsRes.data[0]);
    }

    dispatch({ type: 'SET_USER_SETTINGS', payload: userSettings });
    payload['userSettings'] = userSettings;

    // Get users selected incidents
    let incidents = await getUsersSavedIncidents(payload);
    dispatch({ type: 'USER_INCIDENTS', payload: incidents });

    // Get the calldown incident and setting ID

    let f = {
      where: {
        // and: [
        //     {
        //         pvGroupID: payload.selectedGroupID,
        //     },
        //     {
        //         pvSettingType: CALLDOWN_INCIDENT_SETTING
        //     },
        //     {
        //         pvAccountID: payload.accountInfo.id
        //     }
        // ]
        pvSettingType: CALLDOWN_INCIDENT_SETTING,
      },
    };

    let settingsRes = await apiClient.get(
      PROCESSENV.REACT_APP_PALMETTO_ENDPOINT + '/api/settings?filter=' + JSON.stringify(f),
    );

    if (settingsRes && settingsRes.data && settingsRes.data.length) {
      // We store the incident name and id in the setting because other accounts aren't guaranteed to have access to the incidents that admins do
      const setting = settingsRes.data[0];
      let settingData = null;
      try {
        settingData = JSON.parse(setting.pvSettingValue);
        dispatch({ type: 'SELECTED_INCIDENT_SETTING_ID', payload: setting.pvSettingID });
      } catch (err) {
        console.error('Error parsing calldown incident setting:', err);
      }
      if (settingData) {
        // Get Incident Object (we at least need the name and ID)

        let cIncidentRes = null;
        try {
          cIncidentRes = await apiClient.get(
            `${PROCESSENV.REACT_APP_PALMETTO_ENDPOINT}/api/incidents/${settingData.pvIncidentID}`,
          );
        } catch (err) {
          console.error('Error getting Calldown Incident:', err);
        }

        if (cIncidentRes && cIncidentRes.data) {
          dispatch({
            type: 'SELECTED_INCIDENT_FOR_CALLDOWN',
            payload: cIncidentRes.data,
          });
        } else {
          // Sets the bare minimum data we need
          dispatch({ type: 'SELECTED_INCIDENT_FOR_CALLDOWN', payload: settingData });
        }
      } else {
        dispatch({ type: 'SELECTED_INCIDENT_FOR_CALLDOWN', payload: incidents[0] || {} });
      }
    } else {
      dispatch({ type: 'SELECTED_INCIDENT_SETTING_ID', payload: null });
      dispatch({ type: 'SELECTED_INCIDENT_FOR_CALLDOWN', payload: incidents[0] || {} });
    }

    // Show Application
    dispatch({ type: 'APPLICATION_LOADING_STATE', payload: false });
  };
}

export function saveSelectedIncidentSetting(payload, callback) {
  return async function (dispatch, getState) {
    const { calldownIncidentSettingID } = getState().rootReducer;
    // save with users an active group

    const newSettingValue = {
      pvIncidentID: payload.pvIncidentID,
      pvIncidentName: payload.pvIncidentName,
    };

    try {
      let body = {
        pvSettingID: calldownIncidentSettingID,
        pvSettingType: CALLDOWN_INCIDENT_SETTING,
        pvSettingValue: JSON.stringify(newSettingValue),
        pvGlobalSetting: 1,
        // pvGroupID: selectedGroup,
        // pvAccountID: user.id
      };

      let resp = await apiClient.put(
        process.env.REACT_APP_PALMETTO_ENDPOINT + '/api/settings',
        body,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      dispatch({ type: 'SELECTED_INCIDENT_FOR_CALLDOWN', payload: payload });

      if (!calldownIncidentSettingID && resp.data) {
        // Check for the ID in the response
        dispatch({ type: 'SELECTED_INCIDENT_SETTING_ID', payload: resp.data.pvSettingID });
      }

      window.dispatchEvent(
        new CustomEvent('show-toast', {
          detail: { message: 'Calldown incident saved successfully' },
        }),
      );
      callback && callback();
    } catch (e) {
      console.error('error occurred while saving selected incident setting', e);
      window.dispatchEvent(
        new CustomEvent('show-toast', {
          detail: { message: 'An error occurred while saving the new calldown incident' },
        }),
      );
    }
  };
}

export const setPersona = (type) => {
  return function (dispatch) {
    dispatch({ type: 'SET_PERSONA_TYPE', payload: type });
  };
};

/**
 * Note: Not an action
 * Takes the Entry Incident array and turns it into an object for more efficient checking
 * @param {Array} arr array
 * @returns object
 */
export function getEntryIncidentObject(arr) {
  const toRet = {};
  for (const item in arr) {
    // noinspection JSUnresolvedReference
    if (item.incident_id && item.entry_id && item.entry_type) {
      if (!toRet[item.incident_id]) {
        toRet[item.incident_id] = {};
      }
      if (!toRet[item.incident_id][item.entry_id]) {
        toRet[item.incident_id][item.entry_id] = {};
      }
      toRet[item.incident_id][item.entry_id] = item;
    }
  }
  return toRet;
}

export function contactSupport(payload, callback) {
  return async function (dispatch, getState) {
    const { user, selectedGroup } = getState().rootReducer;

    function getSubject(payload) {
      let footer = '\n';
      footer += 'Name: ' + user.ncPersonGivenName + ' ' + user.ncPersonSurName + '\n';
      footer += 'Email: ' + user.email + '\n';
      // noinspection JSUnresolvedReference
      user.account2groups.forEach(function (group) {
        if (group.pvGroupID === selectedGroup) {
          // noinspection JSUnresolvedReference
          footer += 'Group: ' + group.pvGroupName + '\n';
        }
      });
      // noinspection JSUnresolvedReference
      user.account2positions.forEach(function (position) {
        if (position.positions && position.positions.pvGroupID === selectedGroup) {
          footer += 'Position: ' + position.pvPositionName + '\n';
        }
      });
      return payload.message + '\n' + footer;
    }

    const emailPayload = {
      smsEmailObjects: [
        {
          pvMessageType: 'EMAIL',
          pvEmailMessageText: getSubject(payload),
          pvMessageHtml: '',
          pvEmailSubject: `Calldown Support - ${payload.subject}`,
          pvRecipientFirstName: 'support',
          pvRecipientLastName: ' ',
          pvRecipientEmailAddress: 'support@earthtechint.com',
        },
      ],
    };

    try {
      // Remove the `/dev` as the messages endpoint doesn't use that
      let resp = await apiClient.post(
        `${process.env.REACT_APP_PALMETTO_ENDPOINT.replace(
          /\/[a-z]+$/,
          '',
        )}/messages/send?user_id=${user.id}`,
        emailPayload,
      );
      if (callback) {
        callback(null, resp.data);
        window.dispatchEvent(
          new CustomEvent('show-toast', {
            detail: { message: 'The message was sent successfully' },
          }),
        );
      }
    } catch (err) {
      console.error('Error sending support message:', err);
      if (callback) {
        callback(err);
        window.dispatchEvent(
          new CustomEvent('show-toast', {
            detail: { message: 'An error occured while sending the message' },
          }),
        );
      }
    }
  };
}
