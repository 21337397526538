import React from 'react';
import { createRoot } from 'react-dom/client';
import AppShell from './AppShell';
import store from './redux/store/store.js';
import { Provider } from 'react-redux';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import AppTheme from './theme';
import Toast from './components/utilities/Toast';
// import * as serviceWorker from './serviceWorker';

// Local CSS imports
import '@zawarski/palmetto-ui-components/dist/styles/common.css';
import '@zawarski/palmetto-ui-components/dist/styles/common.login.css';
import '@zawarski/palmetto-ui-components/dist/styles/iron-flex-layout.css';
import '@zawarski/palmetto-ui-components/dist/styles/iron-flex-layout-classes.css';

// import { SW_INIT, SW_UPDATE } from "./redux/constants/action-types";
// if (window.location.pathname !== '/') {
//   window.location.pathname = '/';
// }
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <StyledEngineProvider injectFirst>
    {/* <ScriptsLoader /> */}
    <Provider store={store}>
      <ThemeProvider theme={AppTheme}>
        <AppShell />
        <Toast />
      </ThemeProvider>
    </Provider>
  </StyledEngineProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.register({
//   onSuccess: () => store.dispatch({ type: SW_INIT }),
//   onUpdate: (registration) => {
//     store.dispatch({ type: SW_UPDATE, payload: registration })
//   },
// });
