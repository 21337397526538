// import { withPalmettoAuth } from './components/auth/PalmettoAuth';
import React from 'react';
import PalmettoAuthV2 from './components/auth/PalmettoAuthV2';
import { datadogLogs } from '@datadog/browser-logs';
import { PROCESSENV } from './common/processenv';
import App from './App';

// datadogLogs.init({
//   clientToken: PROCESSENV.REACT_APP_DATADOG_CLIENT_TOKEN,
//   datacenter: 'us',
//   env: PROCESSENV.REACT_APP_DATADOG_STAGING_ENV,
//   forwardErrorsToLogs: false,
//   sampleRate: 100,
// });
//
// export default withPalmettoAuth();
const AppShell = () => {
  datadogLogs.init({
    clientToken: PROCESSENV.REACT_APP_DATADOG_CLIENT_TOKEN,
    datacenter: 'us',
    env: PROCESSENV.REACT_APP_DATADOG_STAGING_ENV,
    forwardErrorsToLogs: false,
    sampleRate: 100,
  });
  return (
    // <App />
    <PalmettoAuthV2>
      <App />
    </PalmettoAuthV2>
  );
};

export default AppShell;
