// noinspection JSUnresolvedReference

import { apiClient } from '../../common/axios_instance';
import { PROCESSENV } from '../../common/processenv';

export const getUsersSavedIncidents = async (payload) => {
  let selectedIncSettings;

  for (let i = 0; i < payload?.userSettings.length; i++) {
    if (payload.userSettings[i].pvSettingType === 'selectedIncidents') {
      selectedIncSettings = payload.userSettings[i].pvSettingValue;
    }
  }

  if (selectedIncSettings) {
    let incidentIds = selectedIncSettings || '[]';
    let savedIncidents = [];
    //Sort and remove duplicates in case of bad data
    let obj = JSON.parse(incidentIds);
    //Remove duplicates (JIC)
    obj.forEach(function (item) {
      if (savedIncidents.indexOf(item) < 0) {
        savedIncidents.push(item);
      }
    });

    const incidentFilter = determineIncidentsFilter(payload);
    let incidentsRes = await apiClient.get(
      `${PROCESSENV.REACT_APP_PALMETTO_ENDPOINT}/api/incidents?filter=${JSON.stringify(
        incidentFilter,
      )}`,
    );

    return parseSelectedIncidents(incidentsRes.data || [], payload.selectedGroupID, savedIncidents);
  }

  return [];
};

function _checkExerciseStatus(incident) {
  if (incident && incident.pvExercise) {
    // Return exercise that is in progress
    if (incident.pvExerciseStatus && incident.pvExerciseStatus === 'In Progress') {
      return true;
    }
  } else {
    //since this is not an exercise treat it as a normal incident
    return true;
  }
}

function parseSelectedIncidents(allIncidents, activeGroup, savedIncidentsInSettings) {
  let defaultIncidents = [];

  let data = [];

  for (let i = 0; i < allIncidents.length; i++) {
    for (let j = 0; j < allIncidents[i].incident2groups.length; j++) {
      if (allIncidents[i].incident2groups[j].pvGroupID === activeGroup) {
        data.push(allIncidents[i]);
        break;
      }
    }
  }

  // Find the default incidents

  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data[i].incident2groups.length; j++) {
      if (
        data[i].incident2groups[j].pvGroupID === activeGroup &&
        data[i].incident2groups[j].pvAutoAssign
      ) {
        defaultIncidents.push(data[i]);
        break;
      }
    }
  }

  let updatedIncidents = [];
  let filledIncidents = [];

  //Find the common incidents
  for (let i = 0; i < savedIncidentsInSettings.length; i++) {
    for (let j = 0; j < data.length; j++) {
      if (savedIncidentsInSettings[i] === data[j].pvIncidentID) {
        filledIncidents.push(data[j]);
        break;
      }
    }
  }

  updatedIncidents = updatedIncidents.concat(filledIncidents);
  updatedIncidents = updatedIncidents.concat(defaultIncidents);

  // here we are getting rid of exercise if they are not started
  let arr = [];
  for (let i = 0; i < updatedIncidents.length; i++) {
    let incident = updatedIncidents[i];
    if (_checkExerciseStatus(incident)) {
      arr.push(incident);
    }
  }

  // Remove duplicates
  let uniqueIncidents = [],
    track = {};
  for (let i = 0; i < arr.length; i++) {
    if (!track[arr[i].pvIncidentID]) {
      track[arr[i].pvIncidentID] = arr[i].pvIncidentID;
      uniqueIncidents.push(arr[i]);
    }
  }

  return uniqueIncidents;
}

function determineIncidentsFilter(payload) {
  let filter = {
    where: {
      and: [
        {
          pvStatus: {
            neq: 'CLOSED',
          },
        },
        {
          or: [],
        },
      ],
    },
  };
  if (payload.selectedGroupID) {
    let activeGroup = payload.selectedGroupID;
    filter.where.and[1].or.push({ pvGroupID: activeGroup });
  } else {
    filter.where.and[1].or.push({ pvGroupID: -1 });
  }

  filter.include = { relation: 'incident2groups', scope: { relation: 'incidents' } };
  return filter;
}
