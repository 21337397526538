/**
 * This stores the Admin App's state. It is kept separate from the Main Root Reducer to help reduce the chances of a conflict.
 */

import { TEMPLATE_TYPES } from '../constants/action-types';

const initialState = {
  currentEntry: null,
};

export default function templateReducer(state = initialState, action) {
  const newState = {};
  if (TEMPLATE_TYPES[action.type]) {
    newState[TEMPLATE_TYPES[action.type]] = action.payload;
    return Object.assign({}, state, newState);
  }

  return state;
}
