import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Local Component Imports
import { PrimaryButton } from '@zawarski/palmetto-ui-components';

// // Local CSS imports
import '@zawarski/palmetto-ui-components/dist/styles/common.css';
import '../../styles/common.login.css';
import '@zawarski/palmetto-ui-components/dist/styles/iron-flex-layout.css';
import '@zawarski/palmetto-ui-components/dist/styles/iron-flex-layout-classes.css';

const mapStateToProps = (state) => {
  const { browser, rootReducer } = state;
  return {
    browser,
    rootReducer,
  };
};

class Login extends React.Component {
  state = {
    login: '',
    username: '',
    password: '',
    errors: [],
  };

  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    // const login =  (
    //     <div className="layout vertical center vertical-center full-height">
    //         <div className="layout vertical logincontainer">
    //             <div className="layout horizontal center center-justified app-logo-container">
    //                 <img className="app-logo" src="/images/launcher.png" alt="Palmetto Logo"/>
    //             </div>
    //             <div className="layout horizontal center-justified">
    //                 <span className="app-title">{process.env.REACT_APP_APP_TITLE}</span>
    //             </div>
    //             {
    //                 this.state.errors.length ?
    //                     <div style={{margin: "20px 50px 0 50px"}}>
    //                         <ErrorBox errors={this.state.errors}/>
    //                     </div>
    //                     : ""
    //             }
    //             <div onKeyPress={this.handleKeyPress} className="flex login-input-container">
    //                 <div className="layout horizontal">
    //                     <NormalTextField
    //                         id="username-box"
    //                         label="Username"
    //                         placeholder="Enter username"
    //                         className="flex"
    //                         value={this.state.username}
    //                         onChange={this.handleChange('username')}
    //                         margin="normal"
    //                         InputLabelProps={{
    //                             shrink: true,
    //                         }}
    //                     />
    //                 </div>
    //                 <div className="layout horizontal">
    //                     <NormalTextField
    //                         id="password-box"
    //                         label="Password"
    //                         type="password"
    //                         placeholder="Enter password"
    //                         className="flex"
    //                         value={this.state.password}
    //                         onChange={this.handleChange('password')}
    //                         margin="normal"
    //                         InputLabelProps={{
    //                             shrink: true,
    //                         }}
    //                     />
    //                 </div>
    //                 <div className="height-20"></div>
    //                 <div className="layout horizontal">
    //                     <PrimaryButton className="flex login-btn" onClick={this.loginFunc}
    //                                    style={{maxWidth: "unset"}}>Login</PrimaryButton>
    //                 </div>
    //             </div>
    //             <div className="login-section-break"></div>
    //             <div className="layout horizontal center center-justified request-account-section">
    //                 <span>Don’t have an account? </span> &nbsp; <a target="_blank" rel="noopener noreferrer"
    //                                                                href={process.env.REACT_APP_PALMETTO_ENDPOINT + "/#/requestaccount"}
    //                                                                className="bottom-link">Request access</a>
    //             </div>
    //         </div>
    //     </div>
    // );
    return (
      <div className='layout vertical center vertical-center full-height'>
        <div className='layout vertical logincontainer'>
          <div className='layout horizontal center center-justified app-logo-container'>
            <img className='app-logo' src='/images/launcher.png' alt='Palmetto Logo' />
          </div>
          <div className='layout horizontal center-justified'>
            <span className='app-title'>{process.env.REACT_APP_APP_TITLE}</span>
          </div>
          <div
            style={{
              textAlign: 'center',
              height: '50px',
              paddingTop: '24px',
              color: 'rgba(0,0,0,67)',
              fontSize: '14px',
            }}>
            Please use the Portal to access this site.
          </div>
          <div className='layout horizontal center-justified'>
            <PrimaryButton
              className='flex login-btn'
              style={{ maxWidth: '220px' }}
              onClick={() => {
                window.location.href = 'https://portal.palmettoeoc.com';
              }}>
              NAVIGATE TO PORTAL
            </PrimaryButton>
          </div>
          <div
            style={{
              textAlign: 'center',
              paddingLeft: '64px',
              paddingRight: '64px',
              paddingTop: '44px',
              color: 'rgba(107,107,107,30)',
              fontSize: '14px',
            }}>
            Access to this site is now available only through the portal on the following link:
          </div>
          <div
            style={{ fontSize: '16px', paddingTop: '4px' }}
            className='layout horizontal center-justified'>
            <a target='_blank' rel='noopener noreferrer' href='https://portal.palmettoeoc.com'>
              portal.palmettoeoc.com
            </a>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  processLogin: PropTypes.func,
};

export default connect(mapStateToProps, null)(Login);
