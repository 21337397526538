export const PROCESSENV = {
  BROWSER: process.env.BROWSER || 'none',
  NPM_TOKEN: process.env.NPM_TOKEN || 'ad8e45b258d30f71d2df9783befc8582d4fdb718',
  REACT_APP_APP_TYPE: process.env.REACT_APP_APP_TYPE || 'palmetto',
  REACT_APP_APP_NAME: process.env.REACT_APP_APP_NAME || 'PALMETTO',
  REACT_APP_APP_TITLE: process.env.REACT_APP_APP_TITLE || 'Call-down (DEV)',
  REACT_APP_APP_IMAGE:
    process.env.REACT_APP_APP_IMAGE || 'https://www.palmettoeoc.com/images/launcher.png',
  REACT_APP_PALMETTO_ENDPOINT:
    process.env.REACT_APP_PALMETTO_ENDPOINT || 'https://dev.palmettoeoc.com',
  REACT_APP_CALLDOWN_API_ENDPOINT:
    process.env.REACT_APP_CALLDOWN_API_ENDPOINT ||
    'https://mozcwly7if.execute-api.us-east-1.amazonaws.com/dev',
  REACT_APP_PALMETTO_SERVERLESS_DOCUMENTS_ENDPOINT:
    process.env.REACT_APP_PALMETTO_SERVERLESS_DOCUMENTS_ENDPOINT ||
    'https://e7jztl5ew4.execute-api.us-east-1.amazonaws.com/dev',
  REACT_APP_PASSWORD_ENCRYPTION_KEY:
    process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY || '253D3FB468A0E24677C28A624BE0F939',
  REACT_APP_AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID || 'AKIAI3UHZR2I4L5IWXTA',
  REACT_APP_AWS_SECRET_ACCESS_KEY:
    process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || '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',
  S3_BUCKET: process.env.S3_BUCKET || 'fallbackbucket',
  REACT_APP_DATADOG_CLIENT_TOKEN:
    process.env.REACT_APP_DATADOG_CLIENT_TOKEN || 'pubc5240b626e9501ec1fc76ab385a673c0',
  REACT_APP_DATADOG_STAGING_ENV: process.env.REACT_APP_DATADOG_STAGING_ENV || 'staging',
  REACT_APP_GOOGLE_API_KEY:
    process.env.REACT_APP_GOOGLE_API_KEY || 'AIzaSyDpfXqpJ4ieDYmvrZcUBG_arjixqjTJ06s',
  PORT: process.env.PORT || 3000,
};
