// noinspection JSIgnoredPromiseFromCall

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setDefaultInfo } from '../../redux/actions/index';
import Login from './Login';
import LoginErrorMsg from './LoginErrorMsg';
import axios from 'axios';
import SitePermissionTypes from '../../redux/constants/sitepermission';

const PalmettoAuthV2 = ({ children }) => {
  const dispatch = useDispatch();
  const [loginSuccessful, setLoginSuccessful] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getJsonFromUrl = (hashBased) => {
    window.location.hash = '';
    let query;
    if (hashBased) {
      let pos = window.location.href.indexOf('?');
      if (pos === -1) return [];
      query = window.location.href.substring(pos + 1);
    } else {
      query = window.location.search.substring(1);
    }
    let result = {};
    query.split('&').forEach(function (part) {
      if (!part) return;
      part = part.split('+').join(' '); // replace every + with space, regexp-free version
      let eq = part.indexOf('=');
      let key = eq > -1 ? part.substring(0, eq) : part;
      let val = eq > -1 ? decodeURIComponent(part.substring(eq + 1)).replace('#', '') : ''; // Removes a hash symbol
      let from = key.indexOf('[');
      if (from === -1) result[decodeURIComponent(key)] = val;
      else {
        let to = key.indexOf(']', from);
        let index = decodeURIComponent(key.substring(from + 1, to));
        key = decodeURIComponent(key.substring(0, from));
        if (!result[key]) result[key] = [];
        if (!index) result[key].push(val);
        else result[key][index] = val;
      }
    });
    return result;
  };

  const getPermissionsForSite = (requestedSite, requestedGroupId, data) => {
    let sitePermissions = [];
    if (!data) {
      return sitePermissions;
    }
    for (let i = 0; i < data.length; i++) {
      let attributes = data[i];
      let site = attributes && Object.keys(attributes) && Object.keys(attributes)[0];
      if (requestedSite === site) {
        for (let j = 0; j < Object.values(attributes)[0].length; j++) {
          let attribute = Object.values(attributes)[0][j];
          let key = Object.keys(attribute)[0];
          // noinspection JSCheckFunctionSignatures
          if (!isNaN(key)) {
            // Group ID
            let groupId = parseInt(key);
            let status = 'DNY';
            if (requestedGroupId === groupId) {
              for (let k = 0; k < Object.values(attribute)[0].length; k++) {
                let values = Object.values(attribute)[0][k];
                if (values && Object.keys(values) && Object.keys(values)[0]) {
                  if (Object.keys(values)[0] === 'S') {
                    // eslint-disable-next-line no-unused-vars
                    status = Object.values(values)[0];
                  } else if (Object.keys(values)[0] === 'P') {
                    sitePermissions = Object.values(values)[0];
                    break;
                  }
                }
              }
              break;
            }
            //console.log("SITE: " + site + " GROUP ID: " + groupId + " STATUS: " + status + " PERMISSIONS: " + JSON.stringify(sitePermissions));
          } else {
            let status = Object.values(attribute)[0];
            console.log('SITE: ' + site + ' STATUS: ' + status);
          }
        }
        break;
      }
    }
    return sitePermissions;
  };

  const processLogin = async () => {
    let parameters = getJsonFromUrl(window.location);
    let permissionType;
    let userInfoData = {
      id: null,
      pvMultipleUser: null,
      pvAdministrator: null,
      pvAccountLocked: null,
      pvPersonGivenName: null,
      pvPersonMiddleName: null,
      pvPersonSurName: null,
      pvOfficePhone: null,
      pvMobilePhone: null,
      pvOrganizationUnitName: null,
      username: null,
      email: null,
      status: null,
      lastUpdated: null,
      pvDomainID: null,
      pvVoid: null,
      pvCanSendBlastEmail: null,
      subscribeToEmail: false,
      accessToken: null,
      pvActiveGroupID: null,
      attributes: [],
      currentGroups: [],
      currentPositions: [],
    };

    if (parameters['id_token']) {
      const headers = {
        Authorization: parameters['id_token'],
      };
      try {
        let tokenVerification = await axios
          .get('https://portal.palmettoeoc.com/prod/public/api/cognito/account', { headers })
          .catch(() => {
            setErrorOccurred(true);
            return setErrorMessage('Failed to authorize token.');
          });

        if (tokenVerification && tokenVerification.data) {
          userInfoData = { ...userInfoData, ...tokenVerification.data };
          const activeGroupID = tokenVerification.data?.pvActiveGroupID || 1;
          let permissions = getPermissionsForSite(
            'CDOWN',
            activeGroupID,
            tokenVerification.data.attributes,
          );

          // *: Change to the CDOWN types.
          switch (permissions && permissions[0]) {
            case 'A':
              permissionType = SitePermissionTypes.A;
              break;
            case 'U':
              permissionType = SitePermissionTypes.U;
              break;
            default:
              permissionType = null;
          }

          try {
            if (!permissionType) {
              setErrorOccurred(true);
              return setErrorMessage("You don't have access to the Calldown App.");
            }

            const authData = {
              id: userInfoData.accessToken,
              userId: userInfoData.id,
              permissionType: permissionType,
            };
            sessionStorage.setItem('userAuthCreds', JSON.stringify(authData));
          } catch (e) {
            console.log('Error occurred', e);
            return;
          }
        }
      } catch (e) {
        console.log('Error occurred', e);
        return;
      }
    }
    if (sessionStorage.getItem('userAuthCreds')) {
      try {
        const authObj = JSON.parse(sessionStorage.getItem('userAuthCreds'));

        const permissions = {
          calldown: {
            permissions: {
              ADMIN: permissionType === SitePermissionTypes.A,
              USER: permissionType === SitePermissionTypes.U,
            },
          },
        };

        // if the user does not have any groups
        if (!userInfoData || !userInfoData.pvActiveGroupID) {
          setErrorOccurred(true);
          return setErrorMessage('No group found.');
        }

        // const userAccountObj = userAccountObjRes.data;
        let selectedGroupID = userInfoData.pvActiveGroupID;

        // *: Just for WEBSOCKET, will remove it upon update
        const palmettoUserAccountInfo = {
          pvVoid: userInfoData.pvVoid,
          id: userInfoData.id,
          ncPersonGivenName: userInfoData.pvPersonGivenName,
          ncPersonMiddleName: userInfoData.pvPersonMiddleName,
          ncPersonSurName: userInfoData.pvPersonSurName,
          pvOfficePhone: userInfoData.pvOfficePhone,
          pvMobilePhone: userInfoData.pvMobilePhone,
          pvMultipleUser: userInfoData.pvMultipleUser,
          pvAdministrator: userInfoData.pvAdministrator,
          pvAccountLocked: userInfoData.pvAccountLocked,
          pvCanSendBlastMessage: userInfoData.pvCanSendBlastEmail,
          subscribeToEmail: userInfoData.subscribeToEmail,
          ncOrganizationUnitName: userInfoData.pvOrganizationUnitName,
          pvDomainID: userInfoData.pvDomainID,
          username: userInfoData.username,
          email: userInfoData.email,
          status: userInfoData.status,
          lastUpdated: userInfoData.lastUpdated,
        };

        window.palmettoToken = authObj;
        window.palmettoUserAccount = palmettoUserAccountInfo;
        window.activeGroup = selectedGroupID;

        let defaultPayload = {
          selectedGroupID: selectedGroupID,
          accountInfo: palmettoUserAccountInfo,
          appPermissions: permissions,
          currentGroups: userInfoData.currentGroups,
          currentPositions: userInfoData.currentPositions,
        };

        await dispatch(setDefaultInfo(defaultPayload));
        setLoginSuccessful(true);
      } catch (err) {
        console.error(err);
        setErrorOccurred(true);
        setErrorMessage('An error occurred during login.');
      }
    }
  };

  useEffect(() => {
    processLogin(); // eslint-disable-next-line
  }, []);

  const logOutUser = () => {
    sessionStorage.removeItem('userAuthCreds');
    window.location.reload();
  };

  return (
    <div className='layout vertical full-height'>
      {loginSuccessful ? (
        <>{children}</>
      ) : errorOccurred ? (
        <LoginErrorMsg error={errorOccurred} message={errorMessage} logout={logOutUser} />
      ) : (
        <Login processLogin={processLogin} />
      )}
    </div>
  );
};

export default PalmettoAuthV2;
