/**
 * Contains all possible types for actions. This is also providing a single source of truth for all possible actions
 */

// Maps Action names to the variables that they change for the root reducer
export const ALL_TYPES = {
  APPLICATION_LOADING_STATE: 'appLoading', // App Loading State
  SET_LOGIN_STATE: 'loginState', // true if a user is logged in
  SET_APP_TYPE: 'appType', // Sets the App Type
  SET_USER_SETTINGS: 'userSettings', // true if a user is logged in
  SET_SELECTED_GROUP: 'selectedGroup', // User Selected Group ID
  SET_SELECTED_GROUP_NAME: 'selectedGroupName', // User Selected Group Name
  SELECTED_INCIDENT_FOR_CALLDOWN: 'calldownIncident', // Admin-selected incident for Calldown
  SELECTED_INCIDENT_SETTING_ID: 'calldownIncidentSettingID', // ID of the setting for storing an incident
  SET_HEALTH: 'health', // true if the connection is still active and healthy
  SET_USER_ACCOUNT_INFO: 'user', // Sets the USer Account Info
  SET_APP_PERMISSIONS: 'permissions', // Sets the app permissions
  USER_INCIDENTS: 'incidents', // All the incidents available to the user
  SET_PERSONA_TYPE: 'personaType', // corresponds to regional/state/county
  CALLDOWN_ENTRY_INCIDENT_ARRAY: 'entryIncidentArray', // temporarily holds the active setting, and incident_id for a calldown entry for an incident
};

export const CATEGORY_TYPES = {
  CATEGORY_DATASET: 'dataset',
  CATEGORY_CURRENT_ENTRY: 'currentEntry',
};

export const COMPANY_TYPES = {
  COMPANY_DATASET: 'dataset',
  COMPANY_CURRENT_ENTRY: 'currentEntry',
  COMPANY_HISTORY: 'history',
  COMPANY_SUMMARY: 'summary',
};

export const ORGANIZATION_TYPES = {
  ORGANIZATION_DATASET: 'dataset',
  ORGANIZATION_CURRENT_ENTRY: 'currentEntry',
  ORGANIZATION_HISTORY: 'history',
};

export const DEVELOPER_TYPES = {
  DEVELOPER_DATASET: 'dataset',
  DEVELOPER_CURRENT_ENTRY: 'currentEntry',
  DEVELOPER_HISTORY: 'history',
};

export const TEMPLATE_TYPES = {
  TEMPLATE_CURRENT_ENTRY: 'currentEntry',
};
