/**
 * This stores the Admin App's state. It is kept separate from the Main Root Reducer to help reduce the chances of a conflict.
 */

import { COMPANY_TYPES } from '../constants/action-types';

const initialState = {
  dataset: [],
  history: [],
  summary: {},
};

export default function companyReducer(state = initialState, action) {
  const newState = {};
  if (COMPANY_TYPES[action.type]) {
    newState[COMPANY_TYPES[action.type]] = action.payload;
    return Object.assign({}, state, newState);
  }

  return state;
}
